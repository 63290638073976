<template>
  <section class="category-form">
    <b-overlay
      :show="loading"
      no-wrap
      spinner-variant="primary"
    />

    <validation-observer
      ref="observer"
      class="w-100"
    >
      <div
        v-for="(question, index) in questions"
        :key="index"
      >
        <div class="w-100" v-if="question.answer">
          <h4>{{ question.question.question }}</h4>
          <iframe
            v-if="question.answer.trim().slice(0,4) === 'http'"
            width="420"
            height="315"
            :src="question.answer"
          />
          <p class="mt-1">
            {{ question.answer }}
          </p>
        </div>
        <div class="w-100">
          <b-row>
            <b-col
              v-for="(mark,index) in question.question.answers"
              :key="index"
              cols="2"
            >
              <b-form-group :label="mark.title">
                <!--                <b-input type="number" v-model="question.marks[index].mark"></b-input>-->
                <b-form-select
                  v-model="question.marks[index].mark"
                  :options="markOptions"
                  label-field="title"
                  value-field="value"
                  :disabled="!news"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <hr>
      </div>
    </validation-observer>
    <div class="w-100 mt-3 d-flex justify-content-end">
      <b-button
        variant="success"
        :disabled="!form.status || !canSendRequest"
        @click="saveQuestionMarks"
      >
        {{ $t('message.Save') }}
      </b-button>
    </div>
  </section>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import { updateApplication, showApplication, getJuries } from '@/api/application'
import { storeJuryMarks } from '@/api/jury'
import { formTemplate } from '@/utils/mixins/formTemplate'
import { quillEditor } from 'vue-quill-editor'
import vSelect from 'vue-select'

function initForm(data) {
  const get = (key, value = null) => _.get(data, key, value)

  return {
    id: get('id', null),
    user: get('user', { profile: null }),
    jury_id: get('jury_id', null),
    status_id: get('status_id', null),
    status: get('status', null),
    name_uz: get('name_uz', null),
    name_ru: get('name_ru', null),
    name_en: get('name_en', null),
  }
}

const actions = {
  add: null,
  update: updateApplication,
  show: showApplication,
}

export default {
  name: 'Form',
  components: {
    ValidationProvider,
    ValidationObserver,
    quillEditor,
    vSelect,
  },
  mixins: [
    formTemplate,
  ],
  props: {
    news: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      actions,
      required,
      snowOption: {
        theme: 'snow',
      },
      form: initForm(),
      allCategories: [],
      allTypes: [],
      juries: [],
      statuses: [],
      questions: [],
      markOptions: [
        { text: 0, value: 0 },
        { text: 0.5, value: 0.5 },
        { text: 1, value: 1 },
        { text: 1.5, value: 1.5 },
        { text: 2, value: 2 },
        { text: 2.5, value: 2.5 },
        { text: 3, value: 3 },
        { text: 3.5, value: 3.5 },
        { text: 4, value: 4 },
        { text: 4.5, value: 4.5 },
        { text: 5, value: 5 },
      ],
      filters: {
        stage_id: this.$route.params.stageId,
      },
      canSendRequest: true,
    }
  },
  created() {
    getJuries({ id: this.$props.id, stage_id: this.$route.params.stageId }).then(res => {
      this.juries = res.data.data.map(item => ({ ...item, ...{ full_name: `${item.first_name} ${item.last_name}`, marks: [] } }))
      this.statuses = res.data.statuses
      this.questions = res.data.questions
      // this.questions.forEach(item => {
      //   item.marks = [];
      //   for(let i = 0;i<item.question.marks_count;i++) {
      //     item.marks.push({mark: null, question_id:item.id});
      //   }
      // });
    })
  },
  methods: {
    reformatData() {
      const form = this.prepareFormData({ id: this.form.id, status_id: this.form.status_id, jury_id: this.form.jury_id })

      return form
    },
    setForm(data) {
      this.form = initForm(data)
    },
    saveQuestionMarks() {
      this.canSendRequest = false
      // const form = this.questions.map(item => item.marks)
      storeJuryMarks({ marks: this.questions, id: this.id, stage_id: this.$route.params.stageId }).then(res => {
        this.canSendRequest = true
        this.$emit('saved')
      })
    },
  },
}
</script>

<style scoped>

</style>
