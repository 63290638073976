import request from '../libs/axios'

export function getApplications(params) {
  return request({
    url: '/api/users/applications',
    method: 'get',
    params,
  })
}

export function storeApplication(data) {
  return request({
    url: '/api/users/applications',
    method: 'post',
    data,
  });
}

export function showApplication(params) {
  console.log(params)
  return request({
    url: `/api/users/applications/${params.id}`,
    method: 'get',
    params: params.params,
  });
}

export function updateApplication(data) {
  return request({
    url:`/api/users/applications/${data.get('id')}`,
    method: 'post',
    data,
  })
}

export function deleteApplication(data) {
  return request({
    url: `/api/users/applications/${data.id}`,
    method: 'delete',
  });
}

export function getJuries(params) {
  return request({
    url: '/api/users/get-juries',
    method: 'get',
    params,
  })
}

export function getStatuses() {
  return request({
    url: '/api/applications/statuses',
    method: 'get',
  })
}

export function rejectApplication(data) {
  return request({
    url: '/api/applications/change-status',
    method: 'post',
    data,
  })
}

export function displayStatusesAll() {
  return request({
    url: '/api/applications/display-statuses-all',
    method: 'post',
  })
}

export function getApplicationMarks(params) {
  return request({
    url: '/api/users/application/marks',
    method: 'get',
    params,
    responseType: params.responseType,
  })
}

export function getApplicationQuestions(params) {
  return request({
    url: '/api/users/application/question-marks',
    method: 'get',
    params,
  })
}

export function removeJury(data) {
  return request({
    url: `/api/applications/jury/${data.id}`,
    method: 'delete',
    data,
  })
}
