import request from '../libs/axios'

export function getJuryApplications(params) {
  return request({
    url: '/api/applications/jury',
    method: 'get',
    params,
  })
}

export function storeJuryApplication(data) {
  return request({
    url: '/api/applications/jury',
    method: 'post',
    data,
  });
}

export function showJuryApplication(data) {
  return request({
    url: `/api/applications/jury/${data.id}`,
    method: 'get',
    data,
  });
}

export function updateJuryApplication(data) {
  return request({
    url:`/api/applications/jury/${data.get('id')}`,
    method: 'post',
    data,
  })
}

export function deleteJuryApplication(data) {
  return request({
    url: `/api/applications/jury/${data.id}`,
    method: 'delete',
  });
}

export function storeJuryMarks(data) {
  return request({
    url: '/api/applications/jury-marks',
    method: 'post',
    data,
  })
}

